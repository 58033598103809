import { updateState } from '../../lib/utils';
import { programFinderActions as types } from '../ActionTypes';

const defaultState = {
  loading: true,
  error: null,
  loadMoreLoading: false,
  loadMoreError: null,
  list: [],
  lastKey: null,
  shortlisting: false,
  shortlistError: null,
  shortlistRefId: null,
  fetchShortlist: false,
  fetchShortlistError: null,
  shortlistedPrograms: [],
  fetchingTimeline: true,
  timelines: {},
  details: {},
  showShortlist: { active: false, shortlistedCount: 0 },
  showThankyou: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, actions = {}) => {
  switch (actions.type) {
    case types.PROGRAM_FINDER_FETCH:
      return updateState(state, { ...actions.payload });
    case types.PROGRAM_FINDER_SUCCESS:
      return updateState(state, { ...actions.payload });
    case types.PROGRAM_FINDER_LOAD_MORE:
      return updateState(state, { ...actions.payload });
    case types.PROGRAM_FINDER_SET_DATA:
      return updateState(state, { ...actions.payload });
    case types.SHORTLIST_PROGRAM_SET:
      return updateState(state, { ...actions.payload });
    case types.COLLEGE_ADMISSION_FETCH:
      return updateState(state, { ...actions.payload });
    default: return state;
  }
};
