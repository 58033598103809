import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import { menu } from '../lib/Constants';
import Intro from './Intro';
import Country from './Country';
import flightIcon from '../Images/plane.png';
import Levels from './Levels';
import Category from './Category';
import Budget from './Budget';
import Intake from './Intake';
import Contact from './Contact';
import StudyPlan from './StudyPlan';
import Preference from './Preference';
import Journey from './Journey';
import * as Cookie from '../lib/Cookie';

function HomePage({ setActiveId, activeId, logoURL }) {
  useEffect(() => {
    if (Cookie.get('activeId')) {
      setActiveId(Cookie.get('activeId'));
    }
  }, []);

  return (
    <div className="mainContainer">
      <div className="logoContainer">
        <img
          className="logo"
          src={logoURL}
          alt="logo"
        />
      </div>
      <div className="shortlistProgress">
        <img className={`progressIcon id${activeId}`} src={flightIcon} alt="icon" />
        {menu.map((m) => (
          <div className="shortlistContainer" key={m.id}>
            <div className={`progressDot ${activeId === m.id && 'active'}`} />
            <span className={`progressDetails ${activeId === m.id && 'active'}`}>{m.name}</span>
          </div>
        ))}
      </div>
      {activeId === '1' && <Intro />}
      {activeId === '2' && <Country />}
      {activeId === '3' && <Levels />}
      {activeId === '4' && <Category />}
      {activeId === '5' && <Budget />}
      {activeId === '6' && <Intake />}
      {activeId === '7' && <Contact />}
      {activeId === '8' && <StudyPlan />}
      {activeId === '9' && <Preference />}
      {activeId === '10' && <Journey />}
    </div>
  );
}

const mapStateToProps = ({ shortlist, company }) => ({
  activeId: shortlist.activeId,
  logoURL: company.companyDetails.logoURL,
});

HomePage.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  activeId: PropTypes.string.isRequired,
  logoURL: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
