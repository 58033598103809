import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { courses } from '../lib/Constants';

function Preference({
  setActiveId, activeId, setFormData,
}) {
  const [preferenceValue, setPreferenceValue] = useState({
    courseId: '',
  });

  useEffect(() => {
    if (Cookie.get('courseId')) {
      setPreferenceValue({ courseId: Cookie.get('courseId') });
    }
  }, []);

  const handlePref = (value) => {
    setPreferenceValue({
      courseId: preferenceValue.courseId === value ? '' : value,
    });
    Cookie.set('courseId', preferenceValue.courseId === value ? '' : value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData({
      courseId: preferenceValue.courseId,
    });
    Cookie.set('courseId', preferenceValue.courseId);
    setActiveId('10');
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main testPage">
        <h3 className="studyPlanHead">Just few more details to personalize your study plan.</h3>
        <div className="preferredLevels">
          <div className="levelHeading">Any examinations already taken?</div>
          <div className="prefereneceBody">
            {courses.map((course) => (
              <label
                className={`prefereneceSelector ${preferenceValue.courseId === course.id && 'active'}`}
                role="presentation"
                onClick={() => handlePref(course.id)}
                key={course.id}
              >
                <label htmlFor={course.id}>{course.name}</label>
              </label>
            ))}
            <label
              className={`prefereneceSelector ${preferenceValue.courseId === 'others' && 'active'}`}
              role="presentation"
              onClick={() => handlePref('others')}
            >
              <label>I haven&apos;t take any exams yet.</label>
            </label>
          </div>
        </div>
      </div>
      <div className={`nextButton ${!preferenceValue.courseId ? 'disabled' : ''}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('8')}>&larr;</span>
        <button type="submit" disabled={!preferenceValue.courseId}>{activeId === 10 ? 'Find your College' : 'Next'}</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
  countryName: shortlist.countryName,
});

Preference.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  activeId: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preference);
