import { toast } from 'react-toastify';
import {
  masterDataActions as actions,
} from '../ActionTypes';
import Service from '../../lib/Service';

export const fetchMasterData = (fetchType, refId) => async (dispatch) => {
  try {
    if (!fetchType) {
      throw new Error('Something went wrong');
    }

    dispatch({
      type: actions.MASTER_SET_DATA,
      payload: {
        [fetchType]: {
          loading: true,
          data: [],
          error: null,
        },
      },
    });
    let qs = '';
    if (refId) {
      qs = `&refId=${refId}`;
    }
    const response = await Service.GET({
      name: 'fetch-master-agent',
      queryString: `fetchType=${fetchType}&companyCode=${'kananprep'}${qs}`,
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });

    dispatch({
      type: actions.MASTER_SET_DATA,
      payload: {
        [fetchType]: {
          loading: false,
          data: response,
          error: null,
        },
      },
    });
    return response;
  } catch (e) {
    dispatch({
      type: actions.MASTER_SET_DATA,
      payload: {
        [fetchType]: {
          loading: false,
          data: null,
          error: e.message,
        },
      },
    });
    return null;
  }
};
