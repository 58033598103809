/* eslint-disable no-unreachable */
import axios from 'axios';
import { toast } from 'react-toastify';
import { shortListActions as actions } from '../ActionTypes';
import * as Cookie from '../../lib/Cookie';
import Service from '../../lib/Service';

export const setActiveId = (activeId) => (dispatch) => {
  Cookie.erase('activeId');
  dispatch({
    type: actions.SET_ACTIVE_ID,
    payload: { activeId },
  });
  Cookie.set('activeId', activeId);
};

export const setFormData = (formData) => (dispatch) => {
  dispatch({
    type: actions.SET_FORM_DATA,
    payload: formData,
  });
};

export const createUser = () => async (dispatch, getState) => {
  try {
    const { formData } = getState().shortlist;
    const { companyDetails } = getState().company;

    const payload = {
      ...formData,
      courseId: formData.courseId === 'others' ? '' : formData.courseId,
      companyCode: companyDetails.companyCode,
      accountId: companyDetails.accountId,
      branchCode: companyDetails.branchCode,
    };

    dispatch({
      type: actions.SUBMIT_FORM_DATA,
      payload: {
        loader: true,
      },
    });

    const response = await Service.POST({
      name: 'register/application',
      payload,
    }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        return res.data;
      }
      throw new Error(res.message);
    });

    if (response.token) {
      const expiryDates = 7;
      Cookie.set('quizky_sp_token', response.token, expiryDates);
      Cookie.set('quizky_sp_uid', response.userData.id, expiryDates);
      Cookie.set('quizky_sp_refId', response.userData.refId, expiryDates);
      Cookie.set('quizky_sp_name', response.userData.firstName, expiryDates);
      axios.defaults.headers.common.Authorization = response.token;
    }

    dispatch({
      type: actions.SUBMIT_FORM_DATA,
      payload: {
        loader: false,
        token: response.token,
      },
    });
  } catch (e) {
    toast.error(e.message);
    dispatch({
      type: actions.SUBMIT_FORM_DATA,
      payload: {
        submitError: e.message,
        loader: false,
      },
    });
  }
};

export const fetchAdminDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: actions.HOME_ADMIN_DETAILS_FETCH,
      payload: {
        adminLoading: true,
        rmInfo: [],
        counsellorInfo: {},
      },
    });
    const response = await Service.GET({
      name: 'get-admin-info',
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: actions.HOME_ADMIN_DETAILS_FETCH,
      payload: {
        adminLoading: false, ...response,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.HOME_ADMIN_DETAILS_FETCH,
      payload: {
        adminLoading: false,
        rmInfo: [],
        counsellorInfo: {},
      },
    });
  }
};

export const validateToken = () => async () => {
  try {
    const token = Cookie.get('quizky_sp_token');
    if (!token) {
      throw new Error('UnAuthorized');
    }
    const response = await Service.GET({
      name: 'validate-auth-token',
      queryString: `token=${token}&version=v2`,
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    if (response.token) {
      const expiryDates = 7;
      Cookie.set('quizky_sp_token', response.token, expiryDates);
      Cookie.set('quizky_sp_uid', response.userData.id, expiryDates);
      Cookie.set('quizky_sp_refId', response.userData.refId, expiryDates);
      Cookie.set('quizky_sp_name', response.userData.firstName, expiryDates);
      axios.defaults.headers.common.Authorization = response.token;
    }
  } catch (error) {
    console.log(error);
  }
};
