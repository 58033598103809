import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SkeletonLoader } from '../../Components/SkeletonLoader';

function Filter({
  workFlows, customLevel,
  categories, intakes, workFlowsLoading, categoriesLoading, customLevelLoading,
  intakesLoading, onChangeHandler, filterValues, countKeysWithValues, showFilter,
}) {
  return (
    <div className="filter">
      <div className="allFilter">
        <h4>All filters</h4>
        <span className="appliedFilter">{countKeysWithValues(filterValues) ? `Applied(${countKeysWithValues(filterValues)})` : ''}</span>
      </div>
      <div className={`${showFilter ? 'active' : ''} mobileFilter`}>
        <hr />
        <div className="workFlowFilter">
          <h4>Preferred country</h4>
          {workFlowsLoading ? <SkeletonLoader width="50%" /> : (
            <div className="rowContainer">
              {workFlows.filter((i) => i.enableForApplication).map((w) => (
                <div>
                  <input
                    type="checkbox"
                    name="workFlowId"
                    id={w.id}
                    value={w.id}
                    onChange={onChangeHandler}
                    checked={filterValues.workFlowId.includes(w.id)}
                  />
                  <label htmlFor={w.id}>{w.workFlowName}</label>
                </div>
              ))}
            </div>
          )}
        </div>
        <hr />
        <div className="levelFilter">
          <h4>Preferred level</h4>
          {customLevelLoading
            ? [...Array(6)].map((a, i) => (
              <SkeletonLoader width="50%" />
            )) : (
              <div className="columnContainer">
                {customLevel?.map((level) => (
                  <div>
                    <input
                      type="checkbox"
                      name="programLevels.id"
                      id={level.id}
                      value={level.id}
                      onChange={onChangeHandler}
                      checked={filterValues['programLevels.id'].includes(level.id)}
                    />
                    <label htmlFor={level.id}>{level.name}</label>
                  </div>
                ))}
              </div>
            )}
        </div>
        <hr />
        <div className="categoryFilter">
          <h4>Preferred program category</h4>
          {categoriesLoading
            ? [...Array(6)].map(() => (
              <SkeletonLoader width="50%" />
            )) : (
              <div className="columnContainer">
                {categories?.map((cat) => (
                  <div className="columnFlex">
                    <input
                      type="checkbox"
                      name="programCategories.categoryId"
                      id={cat.id}
                      value={cat.id}
                      onChange={onChangeHandler}
                      checked={filterValues['programCategories.categoryId'].includes(cat.id)}
                    />
                    <label htmlFor={cat.id}>{cat.name}</label>
                  </div>
                ))}
              </div>
            )}
        </div>
        <hr />
        <div className="intakeFilter">
          <h4>Intake</h4>
          {intakesLoading
            ? [...Array(6)].map((a, i) => (
              <SkeletonLoader width="50%" />
            )) : (
              <div className="columnContainer">
                {intakes?.map((i) => (
                  <div>
                    <input
                      type="checkbox"
                      name="intakeId"
                      id={i.id}
                      value={i.id}
                      onChange={onChangeHandler}
                      checked={filterValues.intakeId.includes(i.id)}
                    />
                    <label htmlFor={i.id}>{i.title}</label>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

Filter.propTypes = {
  workFlowsLoading: PropTypes.bool.isRequired,
  categoriesLoading: PropTypes.bool.isRequired,
  customLevelLoading: PropTypes.bool.isRequired,
  intakesLoading: PropTypes.bool.isRequired,
  workFlows: PropTypes.array,
  customLevel: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  intakes: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  filterValues: PropTypes.object.isRequired,
  countKeysWithValues: PropTypes.object.isRequired,
  showFilter: PropTypes.bool.isRequired,
};

Filter.defaultProps = {
  workFlows: [],
};

const mapStateToProps = ({ programFinder, masterData }) => ({
  programList: programFinder.list,
  loading: programFinder.loading,
  workFlows: masterData.workFlows.data?.sort((a, b) => a.orderNo - b.orderNo),
  workFlowsLoading: masterData.workFlows.loading,
  categories: masterData.categories?.data,
  categoriesLoading: masterData.categories.loading,
  customLevel: masterData.customLevel?.data,
  customLevelLoading: masterData.customLevel.loading,
  intakes: masterData.intakes?.data,
  intakesLoading: masterData.intakes.loading,
});

export default connect(mapStateToProps)(Filter);
