import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/action-shortlist';
import * as action from '../redux/actions/action-masterData';
import '../App.css';
import * as Cookie from '../lib/Cookie';

function Intro({
  setActiveId, setFormData, formData,
}) {
  const [inputData, setInputData] = useState({ firstName: '', lastName: '' });

  useEffect(() => {
    if (Cookie.get('firstName') || Cookie.get('lastName')) {
      setInputData({
        firstName: Cookie.get('firstName') || '',
        lastName: Cookie.get('lastName') || '',
      });
    }
  }, []);

  const handleInput = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
    Cookie.set(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData(inputData);
    Cookie.set('firstName', inputData.firstName);
    Cookie.set('lastName', inputData.lastName);
    setActiveId('2');
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main">
        <h3>Get Started on Your Study Abroad Journey</h3>
        <p>Let&apos;s customize the perfect study abroad plan for you!</p>
        <div className="firstName">
          <label htmlFor="firstName">First Name</label>
          <span className="mandate">*</span>
          <input
            type="text"
            name="firstName"
            id="firstName"
            value={inputData.firstName || formData.firstName}
            onChange={handleInput}
          />
        </div>
        <div className="firstName">
          <label htmlFor="lastName">Last Name</label>
          <span className="mandate">*</span>
          <input
            type="text"
            name="lastName"
            id="lastName"
            value={inputData.lastName || formData.lastName}
            onChange={handleInput}
          />
        </div>
      </div>
      <div className={`nextButton justify-content-end ${!inputData.firstName || !inputData.lastName ? 'disabled' : ''}`}>
        <button type="submit" disabled={!(inputData.firstName && inputData.lastName)}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
});

Intro.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
  fetchMasterData: (fetchType, refId) => dispatch(action.fetchMasterData(fetchType, refId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
