import { companyActions } from '../ActionTypes';
import { updateState } from '../../middleware/utils';

const defaultState = {
  loading: true,
  companyDetails: null,
  error: null,
};

export default (state = defaultState, actions = {}) => {
  switch (actions.type) {
    case companyActions.COMPANY_FETCH_START:
      return updateState(state, { loading: true, error: null });
    case companyActions.COMPANY_FETCH_SUCCESS:
      return updateState(state, { loading: false, companyDetails: actions.payload });
    case companyActions.COMPANY_FETCH_FAILED:
      return updateState(state, { loading: false, error: actions.payload });
    default:
      return state;
  }
};
