export const validateEmail = (email) => {
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)) {
    return true;
  } return false;
};

export const validateMobileNumber = (mobileNumber) => {
  if (/^[123456789]\d{9}$/
    .test(mobileNumber)) {
    return true;
  } return false;
};
