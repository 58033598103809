const FETCH_COUNTRY = 'FETCH_COUNTRY';
const FETCH_CITY = 'FETCH_CITY';
const FETCH_STATE = 'FETCH_STATE';
const SET_ACTIVE_ID = 'SET_ACTIVE_ID';
const SET_VALUE = 'SET_VALUE';
const SET_FORM_DATA = 'SET_FORM_DATA';
const SUBMIT_FORM_DATA = 'SUBMIT_FORM_DATA';

const HOME_ADMIN_DETAILS_FETCH = 'HOME_ADMIN_DETAILS_FETCH';

export const shortListActions = {
  FETCH_COUNTRY,
  FETCH_CITY,
  FETCH_STATE,
  SET_ACTIVE_ID,
  SET_VALUE,
  SET_FORM_DATA,
  SUBMIT_FORM_DATA,
  HOME_ADMIN_DETAILS_FETCH,
};

const PROGRAM_FINDER_FETCH = 'PROGRAM_FINDER_FETCH';
const PROGRAM_FINDER_SUCCESS = 'PROGRAM_FINDER_SUCCESS';
const PROGRAM_FINDER_LOAD_MORE = 'PROGRAM_FINDER_LOAD_MORE';
const PROGRAM_FINDER_SET_DATA = 'PROGRAM_FINDER_SET_DATA';
const SHORTLIST_PROGRAM_SET = 'SHORTLIST_PROGRAM_SET';
const COLLEGE_ADMISSION_FETCH = 'COLLEGE_ADMISSION_FETCH';
const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const programFinderActions = {
  PROGRAM_FINDER_FETCH,
  PROGRAM_FINDER_SUCCESS,
  PROGRAM_FINDER_LOAD_MORE,
  PROGRAM_FINDER_SET_DATA,
  SHORTLIST_PROGRAM_SET,
  COLLEGE_ADMISSION_FETCH,
  AUTH_LOGOUT,
};

const MASTER_SET_DATA = 'MASTER_SET_DATA';

export const masterDataActions = {
  MASTER_SET_DATA,
};

const COMPANY_FETCH_START = 'COMPANY_FETCH_START';
const COMPANY_FETCH_SUCCESS = 'COMPANY_FETCH_SUCCESS';
const COMPANY_FETCH_FAILED = 'COMPANY_FETCH_FAILED';

export const companyActions = {
  COMPANY_FETCH_START,
  COMPANY_FETCH_SUCCESS,
  COMPANY_FETCH_FAILED,
};
