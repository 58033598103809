import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import * as action from '../redux/actions/action-masterData';
import { Loader } from '../Components/Loader';

function Levels({
  setActiveId, setFormData, fetchMasterData, customLevel, loading,
}) {
  const [levelValue, setLevelValue] = useState([]);

  useEffect(() => {
    if (Cookie.get('levelId')) {
      const levelSpilt = Cookie.get('levelId').split(',');
      setLevelValue(levelSpilt);
    }
    fetchMasterData('customLevel');
  }, []);

  const handleLevel = (id) => {
    const prevState = levelValue;
    if (prevState?.includes(id)) {
      setLevelValue((prev) => ([...new Set([...(prev || [])].filter((i) => i !== id))]));
    } else {
      setLevelValue((prev) => ([...new Set([...(prev || []), id])]));
    }
  };

  useEffect(() => {
    if (levelValue.length) {
      const levelValueJoin = levelValue.join(',');
      Cookie.set('levelId', levelValueJoin);
    }
  }, [levelValue.length]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const levelValueJoin = levelValue.join(',');
    setFormData({ levelId: levelValueJoin });
    Cookie.set('levelId', levelValueJoin);
    setActiveId('4');
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="levelMain">
        <h3>
          {`Excited choices for studying in ${Cookie.get('workflowName') || ''}`}
        </h3>
        <div className="preferredLevels">
          <div className="levelHeading">Preferred Levels</div>
          <div className="levelBody">
            {customLevel.filter((i) => i.isActive !== false).map((level) => (
              <div
                className={`levelSelector ${levelValue.includes(level.id) && 'active'}`}
                role="presentation"
                onClick={() => handleLevel(level.id)}
                key={level.id}
              >
                <span>{level.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`nextButton ${!levelValue.length ? 'disabled' : ''}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('2')}>&larr;</span>
        <button type="submit" disabled={!levelValue.length}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist, masterData }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
  countryName: shortlist.countryName,
  customLevel: masterData.customLevel.data,
  loading: masterData.customLevel.loading,
});

Levels.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  fetchMasterData: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  customLevel: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Levels.defaultProps = {
  customLevel: [],
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
  fetchMasterData: (fetchType, refId) => dispatch(action.fetchMasterData(fetchType, refId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Levels);
