import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { BudgetList } from '../lib/Constants';

function Budget({
  setActiveId, formData, setFormData,
}) {
  const [budgetValue, setBudgetValue] = useState({
    budget: '',
  });

  useEffect(() => {
    if (Cookie.get('budget')) {
      setBudgetValue({
        budget: Cookie.get('budget'),
      });
    }
  }, []);

  const handleBudget = (event) => {
    setBudgetValue({
      ...budgetValue,
      [event.target.name]: event.target.value,
    });
    Cookie.set('budget', event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData(budgetValue);
    Cookie.set('budget', budgetValue.budget);
    setActiveId('6');
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main budgetMain">
        <h3>
          {`${formData.firstName || Cookie.get('firstName')} , Let's discuss your budget for studying abroad`}
        </h3>
        <div className="budgetContainer">
          <label className="budgetLabel" htmlFor="budget">Choose your budget</label>
          <select id="budget" name="budget" onChange={handleBudget}>
            <option value="">Select</option>
            {BudgetList.map((i) => (
              <option
                value={i}
                selected={(budgetValue.budget === i) ? 'selected' : ''}
                key={i}
              >
                {`${i} Lakhs`}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={`nextButton ${!budgetValue.budget ? 'disabled' : ''}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('4')}>&larr;</span>
        <button type="submit" disabled={!budgetValue.budget}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
});

Budget.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
