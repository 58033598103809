export const frameDateQuery = (key, startDate, endDate) => {
  let query = null;

  if (startDate || endDate) {
    const rangeQuery = {
      time_zone: '+05:30',
    };
    if (startDate) {
      rangeQuery.gte = startDate;
    }
    if (endDate) {
      rangeQuery.lte = endDate;
    }
    query = {
      range: {
        [key]: {
          ...rangeQuery,
        },
      },
    };
  }

  return query;
};

export const frameRangeQuery = (key, min, max) => {
  let query = null;

  if (min || max) {
    const rangeQuery = {};
    if (min) {
      rangeQuery.gte = min;
    }
    if (max) {
      rangeQuery.lte = max;
    }
    query = {
      range: {
        [key]: {
          ...rangeQuery,
          boost: 2.0,
        },
      },
    };
  }

  return query;
};
