import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { toast } from 'react-toastify';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { fetchCountries } from '../middleware';
import { validateEmail, validateMobileNumber } from '../middleware/regexValidators';

function Contact({
  setActiveId, formData, setFormData,
}) {
  const [userDetails, setUserDetails] = useState({
    mobileNumber: '', emailId: '', countryId: '', dialCode: '', countryCode: '',
  });

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  useEffect(() => {
    if (Cookie.get('mobileNumber') || Cookie.get('emailId') || Cookie.get('countryId')) {
      setUserDetails({
        mobileNumber: Cookie.get('mobileNumber') || '',
        emailId: Cookie.get('emailId') || '',
        countryId: Cookie.get('countryId') || '',
        dialCode: Cookie.get('dialCode') || '',
        countryCode: Cookie.get('countryCode') || '',
      });
    }
  }, []);

  const handleUser = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
    Cookie.set(event.target.name, event.target.value);
  };

  const handleMobChange = (arg1, number, country) => {
    const mobileNumber = number.trim('');
    setUserDetails({
      ...userDetails,
      mobileNumber,
      dialCode: country.dialCode,
      countryCode: country.iso2,
    });
    Cookie.set('mobileNumber', mobileNumber);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(userDetails.emailId)) {
      toast.error('Invalid Email Address');
      return false;
    }
    if (!userDetails.mobileNumber) {
      toast.error('Required Mobile Number');
      return false;
    } if (!validateMobileNumber(userDetails.mobileNumber.trim())) {
      toast.error('Invalid Mobile Number');
      return false;
    }

    setFormData(userDetails);
    Cookie.set('mobileNumber', userDetails.mobileNumber.trim(''));
    Cookie.set('dialCode', userDetails.dialCode);
    Cookie.set('countryCode', userDetails.countryCode);
    Cookie.set('emailId', userDetails.emailId);
    Cookie.set('countryId', userDetails.countryId);
    setActiveId('8');
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main">
        <h3 className="contactInfo">
          {`Almost done! ${formData.firstName || Cookie.get('firstName')}, How can we reach you?`}
        </h3>
        <div className="contactText">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName="form-control custom-country-input"
            placeholder=""
            onPhoneNumberChange={handleMobChange}
            defaultCountry={Cookie.get('countryCode') || 'in'}
            inputMode="numeric"
            value={userDetails.mobileNumber}
          />
        </div>
        <div className="contactText">
          <label htmlFor="emailId">Email address</label>
          <input type="email" name="emailId" id="emailId" value={userDetails.emailId} onChange={handleUser} />
        </div>
        <div className="contactSelect">
          <label className="budgetLabel" htmlFor="countryId">Citizen Country</label>
          <select id="countryId" name="countryId" onChange={handleUser}>
            <option value="">Select</option>
            {countries.map((c) => (
              <option
                key={c.id}
                value={c.id}
                selected={(formData.countryId || Cookie.get('countryId')) === c.id ? 'selected' : ''}
              >
                {c.name}
              </option>
            ))}
          </select>
        </div>

      </div>
      <div className={`nextButton ${!(userDetails.mobileNumber && userDetails.emailId && userDetails.countryId) && 'disabled'}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('6')}>&larr;</span>
        <button type="submit" disabled={!(userDetails.mobileNumber && userDetails.emailId && userDetails.countryId)}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
  countries: shortlist.countries,
  states: shortlist.states,
  cities: shortlist.cities,
});

Contact.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
