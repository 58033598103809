import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { enrollList, levelList } from '../lib/Constants';

function StudyPlan({
  setActiveId, setFormData,
}) {
  const [studyDetails, setStudyDetails] = useState({
    eduLevel: '',
    startDate: '',
  });

  useEffect(() => {
    if (Cookie.get('eduLevel') || Cookie.get('startDate')) {
      setStudyDetails({
        eduLevel: Cookie.get('eduLevel'),
        startDate: Cookie.get('startDate'),
      });
    }
  }, []);

  const handleStudyPlan = (event) => {
    setStudyDetails({
      ...studyDetails,
      [event.target.name]: event.target.value,
    });
    Cookie.set(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData(studyDetails);
    Cookie.set('eduLevel', studyDetails.eduLevel);
    Cookie.set('startDate', studyDetails.startDate);
    setActiveId('9');
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main studyPlan">
        <h3 className="studyPlanHead">Just few more details to personalize your study plan.</h3>
        <div className="contactSelect">
          <label className="budgetLabel" htmlFor="eduLevel">Current Education Level</label>
          <select id="eduLevel" name="eduLevel" onChange={handleStudyPlan}>
            <option value="">Select</option>
            {levelList.filter((i) => i.isPrimary).map((l) => (
              <option
                key={l.type}
                value={l.type}
                selected={studyDetails.eduLevel === l.type ? 'selected' : ''}
              >
                {l.name}
              </option>
            ))}
          </select>
        </div>
        <div className="contactSelect">
          <label className="budgetLabel" htmlFor="startDate">Planning for Study Abroad</label>
          <select id="startDate" name="startDate" onChange={handleStudyPlan}>
            <option value="">Select</option>
            {enrollList.map((l) => (
              <option
                key={l.id}
                value={l.id}
                selected={studyDetails.startDate === l.id ? 'selected' : ''}
              >
                {l.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={`nextButton ${!(studyDetails.eduLevel && studyDetails.startDate) ? 'disabled' : ''}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('7')}>&larr;</span>
        <button type="submit" disabled={!(studyDetails.eduLevel && studyDetails.startDate)}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist }) => ({
  activeId: shortlist.activeId,
});

StudyPlan.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (id) => dispatch(actions.setFormData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyPlan);
