import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import { connect } from 'react-redux';
import Homepage from './Pages/Homepage';
import { Loader } from './Components/Loader';
import { fetchCompany } from './redux/actions/actions-company';

function App({ apiLoading, getCompany }) {
  useEffect(() => {
    getCompany();
  }, []);

  if (apiLoading) {
    return <Loader />;
  }

  return (
    <Homepage />
  );
}

App.propTypes = {
  apiLoading: PropTypes.bool.isRequired,
  getCompany: PropTypes.func.isRequired,
};

const mapStateToProps = ({ company }) => ({
  apiLoading: company.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCompany: async () => dispatch(fetchCompany()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
