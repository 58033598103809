// import { COUNTRY_STATE_CITY_API } from '../constant/Config';

const COUNTRY_STATE_CITY_API = 'https://ycs4dcjr5h.execute-api.ap-south-1.amazonaws.com/dev';

const countryUrl = `${COUNTRY_STATE_CITY_API}/country`;
const stateUrl = `${COUNTRY_STATE_CITY_API}/state`;
const cityUrl = `${COUNTRY_STATE_CITY_API}/city`;

const getData = async (url) => fetch(url)
  .then((res) => res.json())
  .then((res) => {
    if (res.success) return res.data;
    return [];
  });

const fetchCountries = async () => getData(countryUrl);

const fetchStates = async (countryId = null) => getData(countryId ? `${stateUrl}?countryId=${countryId}` : stateUrl);

const fetchCities = async (stateId = null) => getData(stateId ? `${cityUrl}?stateId=${stateId}` : cityUrl);

export {
  fetchCountries,
  fetchStates,
  fetchCities,
};
