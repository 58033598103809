import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { fetchAdminDetails } from '../redux/actions/action-shortlist';

function Thankyou({ fetchAdminInfo, assigneDetails }) {
  const handleJourney = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  useEffect(async () => {
    fetchAdminInfo();
  }, []);

  return (
    <div className="formContainer">
      <div className="thankyouContainer">
        <h3>
          Successfully Shortlisted.
          {' '}
          <span>
            Thank you for your time
            {' '}
            {Cookie.get('firstName') || 'Student'}
          </span>
        </h3>
        <p>Connect with your counsellor if you wish to get additional information on your study abroad journey.</p>
        {assigneDetails && assigneDetails.adminName && (
          <div className="counsellorDtls">
            <img src={assigneDetails.profileImg || require('../Images/profile.png')} alt="placeholder" />
            <h4>
              {assigneDetails.adminName}
              <span>Senior Counsellor</span>
            </h4>
            <h6>
              +91 -
              {' '}
              {assigneDetails.mobileNumber}
              ,
              <span>{assigneDetails.emailId}</span>
            </h6>
          </div>
        )}
      </div>
      <div className="nextButton justify-content-center">
        <button onClick={handleJourney} type="button">Continue your Journey</button>
      </div>
    </div>
  );
}

Thankyou.propTypes = {
  assigneDetails: PropTypes.object.isRequired,
  fetchAdminInfo: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  shortlist,
}) => ({
  adminLoading: shortlist.adminLoading,
  assigneDetails: shortlist.counsellorInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdminInfo: () => dispatch(fetchAdminDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
