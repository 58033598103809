import React from 'react';
import './SkeletonLoader.scss';
import PropTypes from 'prop-types';

function Main({ width }) {
  return (
    <div className="cards">
      <div className="card is-loading">
        <div className="content">
          <p style={{ width }} />
        </div>
      </div>
    </div>
  );
}

Main.propTypes = {
  width: PropTypes.string.isRequired,
};

export default Main;
