import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/action-shortlist';
import * as action from '../redux/actions/action-masterData';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { duration } from '../lib/Constants';
import { Loader } from '../Components/Loader';

function Category({
  setActiveId, setFormData, fetchMasterData, categories, loading,
}) {
  const [categoryDetails, setCategoryDetails] = useState('');
  const [durationDetails, setDurationDetails] = useState('');

  useEffect(() => {
    if (Cookie.get('categoryId') || Cookie.get('durationId')) {
      const categorySplit = Cookie.get('categoryId');
      setCategoryDetails(categorySplit);
      setDurationDetails(Cookie.get('durationId'));
    }
    fetchMasterData('categories');
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const categoryJoin = categoryDetails;
    setFormData({ categoryId: categoryJoin, durationId: durationDetails });
    Cookie.set('categoryId', categoryJoin);
    Cookie.set('durationId', durationDetails);
    setActiveId('5');
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="levelMain">
        <h3>
          Excited choices for studying in
          {' '}
          <span>{Cookie.get('workflowName') || ''}</span>
        </h3>
        <div className="preferredLevels">
          <div className="levelHeading">Preferred Program category</div>
          <div className="levelBody">
            <select
              id="categoryId"
              name="categoryId"
              onChange={(e) => {
                setCategoryDetails(e.target.value);
                Cookie.set('categoryId', e.target.value);
              }}
            >
              <option value="">Select</option>
              {categories?.map((i) => (
                <option
                  key={i.id}
                  value={i.id}
                  selected={categoryDetails.includes(i.id) ? 'selected' : ''}
                >
                  {(i.name).toLowerCase()}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="preferredLevels">
          <div className="levelHeading">Duration Preferences</div>
          <div className="levelBody">
            {duration.map((dur) => (
              <div
                className={`levelSelector ${durationDetails === dur.id && 'active'}`}
                key={dur.id}
                role="presentation"
                onClick={() => {
                  setDurationDetails(dur.id);
                  Cookie.set('durationId', dur.id);
                }}
              >
                <span>{dur.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`nextButton ${!(categoryDetails && durationDetails) ? 'disabled' : ''}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('3')}>&larr;</span>
        <button type="submit" disabled={!(categoryDetails && durationDetails)}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist, masterData }) => ({
  activeId: shortlist.activeId,
  countryName: shortlist.countryName,
  categories: masterData.categories?.data,
  loading: masterData.categories.loading,
});

Category.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setFormData: PropTypes.func.isRequired,
  fetchMasterData: PropTypes.func.isRequired,
  categories: PropTypes.array,
};

Category.defaultProps = {
  categories: [],
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
  fetchMasterData: (fetchType, refId) => dispatch(action.fetchMasterData(fetchType, refId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
