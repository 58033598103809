import { updateState } from '../../lib/utils';
import { masterDataActions as types } from '../ActionTypes';

const defaultState = {
  workFlows: {
    loading: true,
  },
  categories: {
    loading: true,
  },
  intakes: {
    loading: true,
  },
  levels: {
    loading: true,
  },
  customLevel: {
    loading: true,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, actions = {}) => {
  switch (actions.type) {
    case types.MASTER_SET_DATA:
      return updateState(state, { ...actions.payload });
    default: return state;
  }
};
