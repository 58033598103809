import React from 'react';
import PropTypes from 'prop-types';
import {
  Routes, Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import App from '../App';
import * as Cookie from '../lib/Cookie';
import CourseFinder from '../Pages/CourseFinder/CourseFinder';

function Router({ token }) {
  return (
    <>
      <Routes>
        {token || Cookie.get('quizky_sp_token')
          ? <Route path="/" element={<CourseFinder />} exact />
          : <Route path="/" element={<App />} exact />}
      </Routes>
      <ToastContainer />
    </>
  );
}

Router.defaultProps = {
  token: null,
};

Router.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = ({ shortlist }) => ({
  token: shortlist.token,
});

export default connect(mapStateToProps)(Router);
