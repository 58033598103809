import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/action-shortlist';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import * as action from '../redux/actions/action-masterData';
import { Loader } from '../Components/Loader';

function Intake({
  setActiveId, setFormData, formData, fetchMasterData, intakes, loading,
}) {
  const [intakeValue, setIntakeValue] = useState(null);

  useEffect(() => {
    if (Cookie.get('intakeId')) {
      setIntakeValue(Cookie.get('intakeId'));
    }
    fetchMasterData('intakes');
  }, []);

  const handleIntake = (event) => {
    setIntakeValue(event.target.value);
    Cookie.set('intakeId', event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData({ intakeId: intakeValue });
    Cookie.set('intakeId', intakeValue);
    setActiveId('7');
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main budgetMain">
        <h3>Now, Choose your preferred intake for starting your studies.</h3>
        <div className="budgetContainer">
          <label className="budgetLabel" htmlFor="intake">Choose Intake</label>
          <select id="intakeId" name="intakeId" onChange={handleIntake}>
            <option value="">Select</option>
            {intakes.map((i) => <option key={i.id} value={i.id} selected={(formData.intakeId || intakeValue) === i.id ? 'selected' : ''}>{i.title}</option>)}
          </select>
        </div>
      </div>
      <div className={`nextButton ${!intakeValue ? 'disabled' : ''}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('5')}>&larr;</span>
        <button type="submit" disabled={!intakeValue}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist, masterData }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
  intakes: masterData.intakes?.data,
  loading: masterData.intakes.loading,
});

Intake.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchMasterData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  intakes: PropTypes.array,
  setFormData: PropTypes.func.isRequired,
};

Intake.defaultProps = {
  intakes: [],
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
  fetchMasterData: (fetchType, refId) => dispatch(action.fetchMasterData(fetchType, refId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intake);
