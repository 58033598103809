import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Cookie from '../lib/Cookie';
import * as actions from '../redux/actions/action-shortlist';

function Journey({ createUser, loader, setActiveId }) {
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    createUser().then(() => {
      navigate('/');
    });
  };

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main journey">
        <h3>
          {`Ready to embark on your study abroad journey in ${Cookie.get('workflowName') || ''}?`}
        </h3>
        <p>We have got all the info we need, begin your  exciting study abroad adventure now</p>
      </div>
      <div className="nextButton">
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('9')}>&larr;</span>
        <button type="submit" disabled={loader}>
          {loader ? 'Submitting...' : (
            <>
              <span className="desk-text">Find your College</span>
              <span className="mobile-text">Submit</span>
            </>
          )}
        </button>
      </div>
    </form>
  );
}

Journey.propTypes = {
  createUser: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  setActiveId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ shortlist }) => ({
  loader: shortlist.loader,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: () => dispatch(actions.createUser()),
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Journey);
