import { combineReducers } from 'redux';
import shortlist from './reducers-shortlist';
import programFinder from './reducers-programFinder';
import masterData from './reducers-masterData';
import company from './reducer-company';

const rootReducer = combineReducers({
  shortlist,
  programFinder,
  masterData,
  company,
});

export default rootReducer;
