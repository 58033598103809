/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import * as cookies from './Cookie';

// Production
const REACT_APP_API_KANAN_CO_BASE_URL = 'https://api.kanan.co/app';

// dev
// const REACT_APP_API_KANAN_CO_BASE_URL_DEV = 'https://ucy101b5x1.execute-api.ap-south-1.amazonaws.com/dev';

const baseUrl = `${REACT_APP_API_KANAN_CO_BASE_URL}`;
axios.defaults.headers.common.Authorization = cookies.get('quizky_sp_token');
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

async function GET(params) {
  const { name, queryString } = params;
  const q = (queryString) ? `?${queryString}` : '';
  try {
    return await axios({
      method: 'get',
      url: `${baseUrl}/${name}${q}`,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

async function POST(params) {
  const { name, queryString, payload } = params;
  const q = (queryString) ? `?${queryString}` : '?';
  try {
    return await axios({
      method: 'post',
      url: `${baseUrl}/${name}${q}`,
      data: payload,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

async function PUT(params) {
  const { name, queryString, payload } = params;
  const q = (queryString) ? `?${queryString}` : '';
  try {
    return await axios({
      method: 'put',
      url: `${baseUrl}/${name}${q}`,
      data: payload,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

async function DELETE(params) {
  const { name, queryString, payload } = params;
  const q = (queryString) ? `?${queryString}` : '';
  try {
    return await axios({
      method: 'delete',
      url: `${baseUrl}/${name}${q}`,
      data: payload,
    }).then((response) => response.data);
  } catch (error) {
    return error;
  }
}

export default {
  GET,
  POST,
  PUT,
  DELETE,
};
