import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import searchIcon from '../../Images/search.png';
import location from '../../Images/location.svg';
import * as actions from '../../redux/actions/action-programFinder';
import * as action from '../../redux/actions/action-masterData';
import { fetchCompany } from '../../redux/actions/actions-company';
import { validateToken } from '../../redux/actions/action-shortlist';
import { Loader } from '../../Components/Loader';
import * as Cookie from '../../lib/Cookie';
import Filter from './Filter';
import Thankyou from '../Thankyou';

function CourseFinder({
  fetchProgramList, programList, fetchMasterData, loading, shortlistProgram, showThankyou, shortlisting, logout, logoURL, getCompany, validateUser, ...props
}) {
  const navigate = useNavigate();
  const [showShortlist, setShowShortlist] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [filterValues, setFilterValues] = useState({});
  const [showFilter, setShowFilter] = useState(false);

  const countKeysWithValues = (data) => {
    let count = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (data[key] && key !== 'searchText') {
        // eslint-disable-next-line no-plusplus
        count++;
      }
    }
    return count;
  };

  useEffect(() => {
    getCompany();
    validateUser();
    props.fetchShortlistedPrograms();
    fetchMasterData('workFlows');
    fetchMasterData('customLevel');
    fetchMasterData('categories');
    fetchMasterData('intakes');
  }, []);

  const handleProgramFetch = (status = false) => {
    fetchProgramList({
      ...filterValues,
      workFlowId: filterValues.workFlowId.length ? filterValues.workFlowId.join(' ') : '',
      'programLevels.id': filterValues['programLevels.id'].length ? filterValues['programLevels.id'].join(' ') : '',
      'programCategories.categoryId': filterValues['programCategories.categoryId'].length ? filterValues['programCategories.categoryId']?.join(' ') : '',
      intakeId: filterValues.intakeId.length ? filterValues.intakeId.join(' ') : '',
    }, status);
  };

  useEffect(() => {
    if (Object.keys(filterValues).length) {
      handleProgramFetch();
    }
  }, [filterValues]);

  const handleToggle = (id) => {
    setShowShortlist((prev) => !prev);
    setActiveId(id);
  };

  useEffect(() => {
    setFilterValues({
      ...filterValues,
      workFlowId: Cookie.get('workflowId') ? Cookie.get('workflowId').split(',') : '',
      'programLevels.id': Cookie.get('levelId') ? Cookie.get('levelId').split(',') : '',
      'programCategories.categoryId': Cookie.get('categoryId') ? Cookie.get('categoryId').split(',') : '',
      intakeId: Cookie.get('intakeId') ? Cookie.get('intakeId').split(',') : '',
    });
  }, []);

  const onSearchHandler = (event) => {
    setFilterValues({ ...filterValues, [event.target.name]: event.target.value });
  };

  const onChangeHandler = (event) => {
    const prevState = filterValues;
    if (prevState[event.target.name].includes(event.target.value)) {
      setFilterValues((prev) => ({
        ...prev,
        [event.target.name]: [...new Set([...(prev[event.target.name] || [])].filter((i) => i !== event.target.value))],
      }));
    } else {
      setFilterValues((prev) => ({
        ...prev,
        [event.target.name]: [...new Set([...(prev[event.target.name] || []), event.target.value])],
      }));
    }
  };

  const shortlist = (data) => {
    const payload = {
      instituteId: data.instituteId,
      programId: data.programId,
      workFlowId: data.workFlowId,
      campusId: data.campusDetails.id,
      intakeId: data.intakeId,
      intakes: [{
        intakeId: data.intakeId,
        status: data.status,
        title: data.intakeName,
      }],
      name: data.name,
      programName: data.name,
      institute: data.instituteDetails.partnerName,
    };
    confirmAlert({
      title: 'Confirm to shortlist',
      message: 'Are you sure to do this.',
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: 'Yes',
          onClick: () => shortlistProgram({ records: JSON.stringify([payload]), refType: 'CourseFinder' }),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const getProgramStatus = (selectedPgm) => {
    let content = (
      <button
        type="button"
        className="shortlistButton"
        onClick={() => shortlist(selectedPgm)}
        disabled={shortlisting}
      >
        {shortlisting ? 'Loading...' : 'Shortlist'}
      </button>
    );
    if (props.shortlistedPrograms.length) {
      const isExist = props.shortlistedPrograms.some((i) => (i.intakeId === selectedPgm.intakeId) && i.programDetails.find((f) => (f.id === selectedPgm.programId)
        && (f.campusId === selectedPgm.campusDetails?.id)));
      if (isExist) {
        content = (
          <button
            type="button"
            className="shortlistButton disableShortlist"
            disabled
          >
            Shortlisted
          </button>
        );
      }
    }

    return content;
  };

  return (
    <div className="mainContainer courseFinder">
      <div className="searchBar">
        <div className="logoContainer">
          <img
            className="logo"
            src={logoURL || 'https://kananco-assests.s3.ap-south-1.amazonaws.com/website_v2/images/index/kanan-logo-new.svg'}
            alt="logo"
            onClick={() => navigate('/')}
            role="presentation"
          />
        </div>
        <div className="profileOuter">
          {!showThankyou
            && (
              <div className="searchOuter">
                <input
                  type="text"
                  name="searchText"
                  id="search"
                  value={filterValues.searchText}
                  placeholder="Search for Programs..."
                  onChange={onSearchHandler}
                />
                <img className="searchIcon" src={searchIcon} alt="search" />
              </div>
            )}
          <div className="dropdown">
            <span>
              {Cookie.get('quizky_sp_name') || 'Student'}
              {' '}
              &#x25BE;
            </span>
            <div className="dropdown-content">
              <p role="presentation" onClick={() => logout()}>Logout</p>
            </div>
          </div>
        </div>
      </div>
      {!showThankyou && (
        <button type="button" className="crFilterButton" onClick={() => setShowFilter(!showFilter)}>
          {showFilter ? 'Hide Filter' : 'Show Filter'}
        </button>
      )}
      <div className="courseContainer">
        {!showThankyou && (
          <>
            <Filter showFilter={showFilter} onChangeHandler={onChangeHandler} countKeysWithValues={countKeysWithValues} filterValues={filterValues} />
            {
              loading
                ? (
                  <div className="courseLoader">
                    <Loader />
                  </div>
                )
                : (
                  <div className="courseList">
                    {programList.length ? programList.map((data) => (
                      <div className="listContainer">
                        <div className="instituteContainer" role="presentation" onClick={() => handleToggle(data.referenceId)}>
                          <div className="courseDesc">
                            <h3>{data.name?.toLowerCase()}</h3>
                            <p className="insName">{data.instituteDetails.partnerName?.toLowerCase()}</p>
                            <p className="univLoc">
                              <img src={location} alt="location" />
                              <span>
                                {data.instituteDetails?.city}
                                {', '}
                                {data.instituteDetails?.state}
                                {', '}
                                {data.instituteDetails?.country}
                              </span>
                            </p>
                            <div className="durationFee">
                              <p>
                                {data.duration}
                                {' '}
                                {data.durationType}
                                {' '}
                                course |
                                {' '}
                                {data.intakeName}
                              </p>
                              {data.tutionFees && (
                                <p>
                                  Tuition Fee:
                                  {data.tutionFees}
                                </p>
                              )}
                            </div>
                          </div>
                          {data.instituteDetails?.partnerLogo ? (
                            <div className="courseLogo">
                              <img src={data.instituteDetails?.partnerLogo?.replace('crampete', 'kananprep-assets')} alt={data.instituteDetails?.partnerName} />
                            </div>
                          ) : (
                            <div className="logoBox">
                              {data.instituteDetails?.partnerName.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div className={`toggleAccordian ${(showShortlist && data.referenceId === activeId) && 'show'}`}>
                          <div className="courseShortlist">
                            <div className="campStudents">
                              {data.instituteDetails.campusNoOfStudents && (
                                <p>
                                  Campus:
                                  {data.instituteDetails.campusNoOfStudents}
                                </p>
                              )}
                              {' '}
                              {data.instituteDetails.noOfFulltimeStudents && (
                                <p>
                                  Full-Time:
                                  {data.instituteDetails.noOfFulltimeStudents}
                                </p>
                              )}
                            </div>
                            {getProgramStatus(data)}
                          </div>
                          <div className="instDesc">
                            <h4>
                              {`Life at ${data.campusDetails.campusName}`}
                            </h4>
                            <p dangerouslySetInnerHTML={{ __html: data.instituteDetails.campusLife }} />
                            {data.instituteDetails?.degreeDetails
                              && (
                                <div className="degreeDetails">
                                  <h4>Degrees:</h4>
                                  {data.instituteDetails?.degreeDetails?.map((d) => (
                                    <p>
                                      {d.param}
                                      {' '}
                                      -
                                      {' '}
                                      {d.value}
                                    </p>
                                  ))}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )) : <p className="text-center">No programs where found!</p>}
                    {!loading && programList.length ? (
                      <div className="loadMore" role="presentation" onClick={() => handleProgramFetch(true)}>
                        Load More
                      </div>
                    ) : ''}
                  </div>
                )
            }
          </>
        )}
        {showThankyou && <Thankyou />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ programFinder, masterData, company }) => ({
  programList: programFinder.list,
  loading: programFinder.loading,
  workFlows: masterData.workFlows.data?.sort((a, b) => a.orderNo - b.orderNo),
  workFlowsLoading: masterData.workFlows.loading,
  categories: masterData.categories?.data?.slice(0, 6),
  categoriesLoading: masterData.categories.loading,
  customLevel: masterData.customLevel?.data,
  customLevelLoading: masterData.customLevel.loading,
  intakes: masterData.intakes?.data?.slice(0, 6),
  intakesLoading: masterData.intakes.loading,
  showThankyou: programFinder.showThankyou,
  shortlisting: programFinder.shortlisting,
  logoURL: company.companyDetails?.logoURL,
  shortlistedPrograms: programFinder.shortlistedPrograms,
});

CourseFinder.propTypes = {
  fetchProgramList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchMasterData: PropTypes.func.isRequired,
  programList: PropTypes.array.isRequired,
  logoURL: PropTypes.string.isRequired,
  showThankyou: PropTypes.bool.isRequired,
  shortlistProgram: PropTypes.array.isRequired,
  shortlisting: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  validateUser: PropTypes.func.isRequired,
  fetchShortlistedPrograms: PropTypes.func.isRequired,
  shortlistedPrograms: PropTypes.array,
};

CourseFinder.defaultProps = {
  shortlistedPrograms: [],
};

const mapDispatchToProps = (dispatch) => ({
  fetchProgramList: (payload, loadMore) => dispatch(actions.fetchProgramList(payload, loadMore)),
  fetchMasterData: (fetchType, refId) => dispatch(action.fetchMasterData(fetchType, refId)),
  shortlistProgram: (payload) => dispatch(actions.shortlistProgram(payload)),
  logout: () => dispatch(actions.logout()),
  getCompany: async () => dispatch(fetchCompany()),
  validateUser: async () => dispatch(validateToken()),
  fetchShortlistedPrograms: (payload) => dispatch(actions.fetchShortlistedPrograms(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseFinder);
