import React from 'react';
import './Loader.scss';

function Main() {
  return (
    <div className="loader">
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
}

export default Main;
