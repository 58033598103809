export const menu = [{ id: '1', name: 'intro' }, { id: '2', name: 'country' }, { id: '3', name: 'levels' }, { id: '4', name: 'category' }, { id: '5', name: 'budget' }, { id: '6', name: 'intake' }, { id: '7', name: 'contact' }, { id: '8', name: 'study plan' }, { id: '9', name: 'preference' }, { id: '10', name: 'start your journey' }];
export const workflow = [{ id: '39', country: 'Canada', logo: 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Flag_of_Canada.png' }, { id: '233', country: 'USA', logo: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_the_United_States_of_America.png' }, { id: '232', country: 'UK', logo: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_the_United_Kingdom_%283_by_2%29.png' }];

export const levels = [{ id: 'bachelors', name: 'Bachelors' }, { id: 'diploma', name: 'Diploma' }, { id: 'masters', name: 'Masters' }, { id: 'pgDiploma', name: 'PG Diploma' }, { id: 'phd/doc', name: 'Phd/Doctorate' }];
export const category = [{ id: 'art&media', name: 'Arts & Media' }, { id: 'engineering', name: 'Engineering' }, { id: 'bus&mgmt', name: 'Business & Management' }, { id: 'socialserv', name: 'Social Services' }, { id: 'csc', name: 'Computer Science' }];
export const duration = [{ id: '1year', name: '1 year' }, { id: '2year', name: '2 year' }, { id: '3year', name: '3 year' }, { id: '4year', name: '4 year' }];

export const courses = [
  { id: 'ieltsAcademic', name: 'IELTS - ACADEMIC' }, { id: 'ielts', name: 'IELTS - GENERAL' },
  { id: 'toefl', name: 'TOEFL' }, { id: 'pte', name: 'PTE' }, { id: 'duolingo', name: 'Duolingo' },
  { id: 'gre', name: 'GRE' }, { id: 'gmat', name: 'GMAT' }, { id: 'sat', name: 'SAT' }];

export const enrollList = [{
  id: 'sixMonths',
  name: 'Within 6 months',
}, {
  id: 'sixToTwelveMonths',
  name: '6-12 months',
}, {
  id: 'afterTwelveMonths',
  name: 'After 12 months',
}];

export const BudgetList = ['5-10', '10-15', '15-20', '20-25'];

export const levelList = [{
  type: 'tenth',
  name: '10th Grade',
  isPrimary: true,
}, {
  type: 'twelth',
  name: '12th Grade',
  isPrimary: true,
}, {
  type: 'diploma',
  name: 'Diploma',
  isPrimary: true,
}, {
  type: 'diploma2',
  name: 'Diploma 2',
}, {
  type: 'diploma3',
  name: 'Diploma 3',
}, {
  type: 'advancedDiploma',
  name: 'Adv Diploma',
  isPrimary: true,
}, {
  type: 'advancedDiploma2',
  name: 'Adv Diploma 2',
}, {
  type: 'bachelors',
  name: 'Bachelors',
  isPrimary: true,
}, {
  type: 'bachelors2',
  name: 'Bachelors 2',
}, {
  type: 'bachelors3',
  name: 'Bachelors 3',
}, {
  type: 'masters',
  name: 'Masters',
  isPrimary: true,
}, {
  type: 'masters2',
  name: 'Masters 2',
}, {
  type: 'masters3',
  name: 'Masters 3',
}, {
  type: 'pgDiploma',
  name: 'PGDiploma',
  isPrimary: true,
}, {
  type: 'phd',
  name: 'PhD',
  isPrimary: true,
}];
