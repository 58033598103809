import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/action-shortlist';
import * as action from '../redux/actions/action-masterData';
import '../App.css';
import * as Cookie from '../lib/Cookie';
import { Loader } from '../Components/Loader';

function Country({
  setActiveId, formData, setFormData, fetchMasterData, workFlows, loading,
}) {
  const [countryDetails, setCountryDetails] = useState([]);

  const handleCountry = (id) => {
    const prevState = countryDetails.slice();
    if (prevState?.includes(id)) {
      setCountryDetails((prev) => ([...new Set([...(prev || [])].filter((i) => i !== id))]));
    } else {
      setCountryDetails((prev) => ([...new Set([...(prev || []), id])]));
    }
  };

  useEffect(() => {
    if (countryDetails.length) {
      const countryJoin = countryDetails.join(',');
      Cookie.set('workflowId', countryJoin);
    }
  }, [countryDetails.length]);

  useEffect(() => {
    if (Cookie.get('workflowId')) {
      const countryIds = Cookie.get('workflowId').split(',');
      setCountryDetails(countryIds);
    }
    fetchMasterData('workFlows');
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const countryJoin = countryDetails.join(',');
    setFormData({ workflowId: countryJoin });
    Cookie.set('workflowId', countryJoin);
    setActiveId('3');
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <div className="main countryMain">
        <h3>
          {`Hi ${formData.firstName || Cookie.get('firstName') || 'Student'}`}
          , Select your Preferred Country
        </h3>
        <p className="destination">Where would you like to study? Choose your dream destination.</p>
        <div className="countryName">
          {workFlows.filter((i) => i.enableForApplication).map((w) => (
            <div
              className={`countrySelector ${countryDetails.includes(w.id) && 'active'}`}
              role="presentation"
              onClick={() => handleCountry(w.id)}
              key={w.id}
            >
              <img className="workFlowLogo" src={w.countryFlag} alt={w.workFlowName} htmlFor={w.id} />
              <label htmlFor={w.id}>{w.workFlowName}</label>
            </div>
          ))}
        </div>
      </div>
      <div className={`nextButton ${!(countryDetails.length) && 'disabled'}`}>
        <span className="prevBtn" role="presentation" onClick={() => setActiveId('1')}>&larr;</span>
        <button type="submit" disabled={!(countryDetails.length)}>Next</button>
      </div>
    </form>
  );
}

const mapStateToProps = ({ shortlist, masterData }) => ({
  activeId: shortlist.activeId,
  formData: shortlist.formData,
  workFlows: masterData.workFlows.data?.sort((a, b) => a.orderNo - b.orderNo),
  loading: masterData.workFlows.loading,
});

Country.propTypes = {
  setActiveId: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  setFormData: PropTypes.func.isRequired,
  fetchMasterData: PropTypes.func.isRequired,
  workFlows: PropTypes.array,
};

Country.defaultProps = {
  workFlows: [],
};

const mapDispatchToProps = (dispatch) => ({
  setActiveId: (id) => dispatch(actions.setActiveId(id)),
  setFormData: (formData) => dispatch(actions.setFormData(formData)),
  fetchMasterData: (fetchType, refId) => dispatch(action.fetchMasterData(fetchType, refId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Country);
