/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  programFinderActions as types,
} from '../ActionTypes';
import * as Cookie from '../../lib/Cookie';
import { frameRangeQuery } from '../../middleware';
import Service from '../../lib/Service';

const ELASTIC_SEARCH_URL = 'https://search-qadmin-es-dr4vrgsbvwg3irlz2hpbha7k3i.ap-south-1.es.amazonaws.com';
const ES_KIBANA_UNAME = 'qadmin_eskib';
const ES_KIBANA_PWD = 'fX,V=D8T';

const setResponse = (payload) => (dispatch) => {
  dispatch({
    type: types.PROGRAM_FINDER_SUCCESS,
    payload,
  });
};

const setData = (data) => ({
  type: types.PROGRAM_FINDER_SET_DATA,
  payload: { ...data },
});

const frameQuery = (payload) => {
  const query = {
    filter: [],
  };
  const keys = Object.keys(payload);
  if (keys.length) {
    keys.map((key) => {
      if (typeof payload[key] === 'object') {
        if (key === 'tutionFees') {
          const feeQuery = frameRangeQuery(key, String(payload[key].min), String(payload[key].max));
          query.filter.push({
            ...feeQuery,
          });
        }
        if (key === 'instituteDetails' || key === 'programLevels' || key === 'programCategories') {
          const objPayload = payload[key];
          const objKey = Object.keys(objPayload);
          if (objPayload[objKey]) {
            query.filter.push({
              multi_match: {
                query: objPayload[objKey],
                fields: `${key}.${objKey}`,
              },
            });
          }
        }
        return true;
      }
      const values = payload[key].split(' ')?.filter((i) => i);
      values.map((value) => {
        if (key === 'searchText') {
          query.filter.push({
            multi_match: {
              query: `*${value}*`,
              type: 'phrase_prefix',
              fields: ['searchText', 'instituteDetails.partnerName'],
            },
          });

          return true;
        }
        query.filter.push({
          multi_match: {
            query: payload[key],
            fields: [key],
          },
        });
        return true;
      });
      return true;
    });
  }

  return query;
};

export const fetchProgramList = (fieldObj, loadMore) => async (dispatch, getState) => {
  try {
    const { lastKey, list } = getState().programFinder;
    let queryParams = {
      bool: {
        filter: [{
          multi_match: {
            query: 'OPEN WAITLIST',
            fields: ['status'],
          },
        }],
      },
    };

    if (fieldObj) {
      const query = await frameQuery(fieldObj);
      queryParams = {
        bool: {
          filter: [...queryParams.bool.filter, ...query.filter],
        },
      };
    }

    const additionalParams = {};

    if (loadMore) {
      dispatch(setData({
        loadMoreLoading: true,
      }));
      additionalParams.search_after = lastKey;
    } else {
      dispatch(setResponse({
        list: [],
        loading: true,
      }));
    }

    const params = {
      size: 10,
      ...additionalParams,
      sort: [
        {
          'referenceId.keyword': {
            order: 'asc',
          },
        },
      ],
      query: {
        ...queryParams,
      },
    };

    await axios({
      method: 'post',
      url: `${ELASTIC_SEARCH_URL}/program-table/_search`,
      data: {
        ...params,
      },
      auth: {
        username: ES_KIBANA_UNAME,
        password: ES_KIBANA_PWD,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      const result = res.data;
      const response = result.hits ? result.hits.hits.map((i) => ({
        ...i._source,
        sourceId: i._id,
      })) : [];
      const len = response.length;
      const lastObj = result.hits.hits[len - 1];

      dispatch(setData({
        lastKey: lastObj ? lastObj.sort : null,
      }));

      if (loadMore) {
        dispatch(setResponse({
          list: [...list, ...response],
          loading: false,
          error: null,
        }));
      } else if (response.length) {
        dispatch(setResponse({
          list: response,
          loading: false,
          error: null,
        }));
      } else {
        dispatch(setResponse({
          list: [],
          loading: false,
          error: null,
        }));
      }

      dispatch(setData({
        loadMoreLoading: false,
      }));
    }).catch((er) => {
      dispatch(setData({
        loadMoreLoading: false,
        loadMoreError: er.message,
      }));
      dispatch(setResponse({
        list: [],
        loading: false,
        error: er.message,
      }));
    });
    // axios.defaults.headers.common.Authtype = 'cognito';
  } catch (error) {
    dispatch(setResponse({
      list: [],
      loading: false,
      error: error.message,
      loadMoreLoading: false,
    }));
  }
};

export const shortlistProgram = (data) => async (dispatch, getState) => {
  const toastId = toast.info('Shortlisting....', { autoClose: false, closeButton: false, closeOnClick: false });
  try {
    const { shortlistedPrograms } = getState().programFinder;
    dispatch({
      type: types.SHORTLIST_PROGRAM_SET,
      payload: {
        shortlisting: true,
        shortlistError: null,
        showShortlist: { active: false, shortlistedCount: 0 },
      },
    });
    const response = await Service.POST({
      name: 'kanan-website/profile-info/shortlist',
      payload: data,
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    const records = response.records || [];
    const newPrograms = records.map((r) => ({
      intakeId: r.intakeId,
      programDetails: [{ id: r.programId, campusId: r.campusId }],
    }));
    dispatch({
      type: types.SHORTLIST_PROGRAM_SET,
      payload: {
        shortlisting: false,
        shortlistError: null,
        shortlistedPrograms: [
          ...shortlistedPrograms,
          ...(newPrograms || []),
        ],
        showShortlist: { active: true, shortlistedCount: newPrograms.length },
        showThankyou: true,
      },
    });
    toast.success('Shortlisted Successfully');
  } catch (e) {
    dispatch({
      type: types.SHORTLIST_PROGRAM_SET,
      payload: {
        shortlisting: false,
        shortlistRefId: null,
        shortlistError: e.message,
        showShortlist: { active: false, shortlistedCount: 0 },
        showThankyou: false,
      },
    });
    toast.error('Internal Server Error!');
  } finally {
    toast.dismiss(toastId);
  }
};

export const fetchShortlistedPrograms = () => async (dispatch) => {
  try {
    dispatch({
      type: types.SHORTLIST_PROGRAM_SET,
      payload: { fetchShortlist: true, shortlistedPrograms: [], showShortlist: { active: false, shortlistedCount: 0 } },
    });
    const response = await Service.GET({
      name: 'kanan-website/profile-info/shortlist',
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: types.SHORTLIST_PROGRAM_SET,
      payload: {
        fetchShortlist: false,
        fetchShortlistError: null,
        shortlistedPrograms: response.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime)),
      },
    });
  } catch (e) {
    dispatch({
      type: types.SHORTLIST_PROGRAM_SET,
      payload: { fetchShortlist: false, fetchShortlistError: e.message, shortlistedPrograms: [] },
    });
    toast.error('Something went wrong!');
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: types.AUTH_LOGOUT,
    payload: {
      logoutLoading: true,
    },
  });
  toast.success('Your logged out!');
  Cookie.eraseByName('quizky_sp_token');
  Cookie.eraseByName('quizky_sp_uid');
  Cookie.eraseByName('quizky_sp_name');
  await Cookie.eraseAllSync();
  dispatch({
    type: types.AUTH_LOGOUT,
    payload: {
      logoutLoading: false,
    },
  });
  window.location.reload();
};
