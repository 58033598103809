import { shortListActions } from '../ActionTypes';
import { updateState } from '../../lib/utils';

const formData = {
  firstName: '',
  lastName: '',
  workflowId: '',
  budget: '',
  intakeId: '',
  mobileNumber: '',
  emailId: '',
  countryId: '',
  eduLevel: '',
};

const initialState = {
  countries: [],
  activeId: '1',
  formData,
  error: null,
  loader: false,
  submitError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, actions = {}) => {
  switch (actions.type) {
    case shortListActions.FETCH_COUNTRY:
      return updateState(state, { ...actions.payload });
    case shortListActions.FETCH_STATE:
      return updateState(state, { ...actions.payload });
    case shortListActions.FETCH_CITY:
      return updateState(state, { ...actions.payload });
    case shortListActions.SET_ACTIVE_ID:
      return updateState(state, { ...actions.payload });
    case shortListActions.SET_VALUE:
      return updateState(state, { ...actions.payload });
    case shortListActions.SET_FORM_DATA:
      return updateState(state, { formData: { ...state.formData, ...actions.payload } });
    case shortListActions.SUBMIT_FORM_DATA:
      return updateState(state, { ...actions.payload });
    case shortListActions.HOME_ADMIN_DETAILS_FETCH:
      return updateState(state, { ...actions.payload });
    default: return state;
  }
};
