import Service from '../../lib/Service';
import { changeFavicon } from '../../middleware/utils';
import { companyActions } from '../ActionTypes';

const setPageDetails = (data) => {
  try {
    document.title = data.companyName;
    if (data.faviconURL) {
      const favicon = document.getElementById('favicon');
      favicon.href = data.faviconURL;
    }
  } catch (e) {
    console.log('Error in setting page details');
  }
};

export const fetchCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: companyActions.COMPANY_FETCH_START,
    });
    const defaultCompanyCode = 'kananprep';
    const domainName = window.location.hostname;
    // const domainName = 'd3frnlrnfhit38.cloudfront.net';
    const resData = await Service.GET({
      name: 'getcompany',
      queryString: `companyCode=${defaultCompanyCode}&hostName=${domainName}`,
    }).then((res) => {
      if (res.success) return res.data;
      throw new Error(res.message);
    });
    changeFavicon(resData.faviconURL);
    if (resData.companyCode === 'kananprep') {
      document.title = resData.companyName || 'Kanan';
    }

    dispatch({
      type: companyActions.COMPANY_FETCH_SUCCESS,
      payload: resData,
    });

    setPageDetails(resData);
  } catch (error) {
    dispatch({
      type: companyActions.COMPANY_FETCH_FAILED,
      payload: error.message,
    });
  }
};
